/* eslint-disable jest/require-hook */
import "./getGeo";
import "~/LaunchDarkly";
import "~/pwa";

import { datadogRum } from "@datadog/browser-rum";

import { isAppletPicker } from "./env";
import { setReduxAuth } from "./hooks/useReduxAuth";
import { AppletName } from "./types";
import { className, modalizeId } from "./utils";

import pkg from "~/../package.json";
import { mount as mountModal } from "~/Applet/ModalLauncher";
import { APPLET_MANIFEST } from "~/AppletManifest";
import { BASE64_FAVICON } from "~/Constant";
import { isDevelopment, isProduction } from "~/env";

const appEnvironment = isProduction ? "prod" : isDevelopment ? "dev" : "stage";
const appVersion = pkg.version;

// Show version in console
if (!isDevelopment) {
  const size = 16;

  const image = new Image();

  image.src = BASE64_FAVICON;
  image.width = size;
  image.height = size;
  image.onload = function () {
    console.info(
      `%c   Omnichannel Applets v${appVersion}`,
      [
        `font-size: ${size}px;`,
        `background: url(${BASE64_FAVICON}) no-repeat;`,
        "background-size: contain;",
      ].join(" ")
    );
  };
}

if (isProduction) {
  // Datadog RUM
  datadogRum.init({
    applicationId: "270d6432-4a68-4719-ab95-1120185e45d4",
    clientToken: "pub23a21296ed86636ddb8e54422ddbec8b",
    site: "datadoghq.com",
    service: "omnichannel-applets",
    env: appEnvironment,
    version: appVersion,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "allow",
    allowedTracingUrls: [
      // explicitly exclude auth for kickdrum rental implementation (sending DD headers for unknown reasons)
      /^https:\/\/(?:(?!auth\.estrack\.com)[^/])+\.(estrack|equipmentshare)\.com/i,
    ],
    enableExperimentalFeatures: ["feature_flags"],
  });

  datadogRum.startSessionReplayRecording();
}

const mountApplets = () => {
  const APPLET_KEYS: Array<keyof typeof APPLET_MANIFEST> = [
    "debug",
    "seo-meta",
    "notifications",
    "keyword-linker",
  ];

  APPLET_KEYS.forEach((APPLET_KEY) => {
    const id = APPLET_MANIFEST[APPLET_KEY].mountId;
    if (id && !document.getElementById(id)) {
      const el = document.createElement("div");
      el.id = id;
      document.body.appendChild(el);
    }
  });

  // check for existing mount points on the DOM
  (Object.keys(APPLET_MANIFEST) as Array<AppletName>).forEach((appletName) => {
    const { mountId, mountClassName, mount } = APPLET_MANIFEST[appletName];

    if (mountId) {
      const el = document.getElementById(mountId) as HTMLDivElement;

      if (el) {
        window.appletDebug(
          `GetElementById > Mounting ${appletName} on ${el.id}`
        );
        mount(el);
      }
    }
    if (mountClassName) {
      Array.from(document.getElementsByClassName(mountClassName)).forEach(
        (el) => {
          window.appletDebug(
            `GetElementById > Mounting ${appletName} on ${el.className}`
          );
          mount(el as HTMLDivElement);
        }
      );
    }
  });

  // check for new mount points on the DOM with the browser native MutationObserver interface
  const mutationCallback: MutationCallback = (mutations) => {
    mutations.forEach((mutation: MutationRecord) => {
      // check each mutation for added nodes and mount any matching applets
      if (mutation.type === "childList") {
        Array.from(mutation.addedNodes).forEach((node: Node) => {
          // if node is an html element
          if (
            node.ELEMENT_NODE === 1 &&
            (node as HTMLElement).tagName === "DIV"
          ) {
            const el = node as HTMLDivElement;

            if (el.id) {
              (Object.keys(APPLET_MANIFEST) as Array<AppletName>).forEach(
                (appletName) => {
                  const { mountId, mount } = APPLET_MANIFEST[appletName];

                  if (mountId) {
                    if (el.id === mountId) {
                      window.appletDebug(
                        `MutationCallback > Mounting ${appletName} on ${el.id}`
                      );
                      mount(el);
                    } else if (el.id === modalizeId(mountId, true)) {
                      window.appletDebug(
                        `MutationCallback > Mounting modal ${appletName} on ${el.id}`
                      );
                      mountModal({ el, appletName });
                    }
                  }
                }
              );
            } else if (
              Array.from(el.classList.values()).some((classListName) =>
                classListName.startsWith(className(""))
              )
            ) {
              (Object.keys(APPLET_MANIFEST) as Array<AppletName>).forEach(
                (appletName) => {
                  const { mountClassName, mount } = APPLET_MANIFEST[appletName];

                  if (mountClassName && el.classList.contains(mountClassName)) {
                    window.appletDebug(
                      `MutationCallback > Mounting ${appletName} on ${el.className}`
                    );
                    mount(el);
                  }
                }
              );
            }
          }
        });
      }
    });
  };

  const observer = new MutationObserver(mutationCallback);

  observer.observe(document.body, {
    attributes: true,
    childList: true,
    characterData: true,
  });

  if (isAppletPicker) {
    const params = new URLSearchParams(location.search);

    const appName = params.get("mountApp");

    if (appName) {
      if (APPLET_MANIFEST[appName as keyof typeof APPLET_MANIFEST]) {
        const el = document.createElement("div");
        document.body.appendChild(el);

        APPLET_MANIFEST[appName as keyof typeof APPLET_MANIFEST].mount(el);
      } else {
        document.write(`Could not find "${appName}" in the applet manifest!`);
      }

      setTimeout(
        () =>
          setReduxAuth({
            isAuthenticated: false,
            isLoading: false,
            identity: undefined,
          }),
        300
      );
    } else {
      const el = document.getElementById("omnichannel-applet-applet-picker");

      if (el?.tagName === "DIV") {
        import("./AppletPicker").then(({ mount }) =>
          mount(el as HTMLDivElement)
        );
      }
    }
  }
};

// check for existing readiness
if (document.readyState === "complete") {
  mountApplets();
} else {
  // check for future readiness
  document.onreadystatechange = () => {
    if (document.readyState === "complete") {
      mountApplets();
    }
  };
}
