import React from "react";
import { BreadcrumbList, ListItem, WithContext } from "schema-dts";

import { JSONLDComponent } from "../JSONLDComponent";

import { toTitleCase } from "~/utils";

export const BreadcrumbListSchema: React.FC = () => {
  const breadcrumbParentEl = document.querySelector(".esr-breadcrumb-block");

  if (!breadcrumbParentEl) {
    return null;
  }

  const breadcrumbItems: Array<ListItem> = Array.from(
    document.querySelectorAll(
      ".esr-breadcrumb-block .esr-breadcrumb.esr-breadcrumb-link,.esr-breadcrumb-block .esr-breadcrumb-disabled:last-child"
    )
  )
  .filter(el => !el.matches(".esr-breadcrumb.esr-breadcrumb-link.w-dyn-bind-empty"))
  .map((el, position) => ({
    "@type": "ListItem",
    position: position + 1,
    item: {
      // @ts-expect-error href does/can exist on el
      "@id": el.href || window.location.href,
      name:
        el.textContent === el.textContent?.toLowerCase()
          ? toTitleCase(el.textContent)
          : el.textContent,
    },
  }));

  if (!breadcrumbItems.length) {
    return null;
  }

  const breadcrumbData: WithContext<BreadcrumbList> = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: breadcrumbItems,
  };

  return <JSONLDComponent structuredData={breadcrumbData} />;
};
