import { Offer, WithContext } from "schema-dts";

import { COUNTRY_SERVED, ORGANIZATION_SCHEMA } from "../constants";

const SHARED_OFFER_PROPS: WithContext<Offer> = {
  "@context": "https://schema.org",
  "@type": "Offer",
  availability: "InStock",
  areaServed: COUNTRY_SERVED,
  offeredBy: ORGANIZATION_SCHEMA,
  businessFunction: {
    "@id": "http://purl.org/goodrelations/v1#LeaseOut",
  },
};

export const RENTAL_DAILY_OFFER: WithContext<Offer> = {
  ...SHARED_OFFER_PROPS,
  name: "Daily Rental",
  leaseLength: {
    "@type": "QuantitativeValue",
    minValue: 1,
    maxValue: 6,
    unitCode: "DAY",
  },
};

export const RENTAL_WEEKLY_OFFER: WithContext<Offer> = {
  ...SHARED_OFFER_PROPS,
  name: "Weekly Rental",
  leaseLength: {
    "@type": "QuantitativeValue",
    minValue: 7,
    maxValue: 27,
    unitCode: "DAY",
  },
};

export const RENTAL_MONTHLY_OFFER: WithContext<Offer> = {
  ...SHARED_OFFER_PROPS,
  name: "4-week Rental",
  leaseLength: {
    "@type": "QuantitativeValue",
    minValue: 28,
    unitCode: "DAY",
  },
};
