/** access environment variables with or without VITE_ prefix */
export const env = (key: string): undefined | string =>
  envVars[`VITE_${key}`] ?? envVars[key] ?? undefined;

/** this looks silly, but it's because env values are statically replaced: https://vitejs.dev/guide/env-and-mode.html#production-replacement */
const envVars: Record<string, undefined | string> = {
  NODE_ENV: process.env.NODE_ENV,
  VITE_USER_NODE_ENV: process.env.VITE_USER_NODE_ENV || process.env.NODE_ENV,
  VITE_OMNICHANNEL_APPLETS_GOOGLE_API_KEY:
    process.env.VITE_OMNICHANNEL_APPLETS_GOOGLE_API_KEY,
  VITE_LOCATION_SCORECARD_GOOGLE_API_KEY:
    process.env.VITE_LOCATION_SCORECARD_GOOGLE_API_KEY,
  VITE_ASSET_RETAIL_QUOTE_GOOGLE_API_KEY:
    process.env.VITE_ASSET_RETAIL_QUOTE_GOOGLE_API_KEY,
  VITE_LAUNCH_DARKLY_CLIENT_ID: process.env.VITE_LAUNCH_DARKLY_CLIENT_ID,
  VITE_AUTH0_DOMAIN: process.env.VITE_AUTH0_DOMAIN,
  VITE_AUTH0_AUDIENCE: process.env.VITE_AUTH0_AUDIENCE,
  VITE_AUTH0_CLIENT_ID: process.env.VITE_AUTH0_CLIENT_ID,
  VITE_AUTH0_CALLBACK_URL: process.env.VITE_AUTH0_CALLBACK_URL,
  VITE_AUTH0_LOGOUT_URL: process.env.VITE_AUTH0_LOGOUT_URL,
  // asset retail quote
  VITE_AUTH0_ASSET_RETAIL_QUOTE_DOMAIN:
    process.env.VITE_AUTH0_ASSET_RETAIL_QUOTE_DOMAIN,
  VITE_AUTH0_ASSET_RETAIL_QUOTE_AUDIENCE:
    process.env.VITE_AUTH0_ASSET_RETAIL_QUOTE_AUDIENCE,
  VITE_AUTH0_ASSET_RETAIL_QUOTE_CLIENT_ID:
    process.env.VITE_AUTH0_ASSET_RETAIL_QUOTE_CLIENT_ID,
  VITE_AUTH0_ASSET_RETAIL_QUOTE_CALLBACK_URL:
    process.env.VITE_AUTH0_ASSET_RETAIL_QUOTE_CALLBACK_URL,
  VITE_AUTH0_ASSET_RETAIL_QUOTE_LOGOUT_URL:
    process.env.VITE_AUTH0_ASSET_RETAIL_QUOTE_LOGOUT_URL,
  // location scorecard
  VITE_AUTH0_LOCATION_SCORECARD_DOMAIN:
    process.env.VITE_AUTH0_LOCATION_SCORECARD_DOMAIN,
  VITE_AUTH0_LOCATION_SCORECARD_AUDIENCE:
    process.env.VITE_AUTH0_LOCATION_SCORECARD_AUDIENCE,
  VITE_AUTH0_LOCATION_SCORECARD_CLIENT_ID:
    process.env.VITE_AUTH0_LOCATION_SCORECARD_CLIENT_ID,
  VITE_AUTH0_LOCATION_SCORECARD_CALLBACK_URL:
    process.env.VITE_AUTH0_LOCATION_SCORECARD_CALLBACK_URL,
  VITE_AUTH0_LOCATION_SCORECARD_LOGOUT_URL:
    process.env.VITE_AUTH0_LOCATION_SCORECARD_LOGOUT_URL,
  // t3 kits
  VITE_AUTH0_T3_KITS_DOMAIN: process.env.VITE_AUTH0_T3_KITS_DOMAIN,
  VITE_AUTH0_T3_KITS_AUDIENCE: process.env.VITE_AUTH0_T3_KITS_AUDIENCE,
  VITE_AUTH0_T3_KITS_CLIENT_ID: process.env.VITE_AUTH0_T3_KITS_CLIENT_ID,
  VITE_AUTH0_T3_KITS_CALLBACK_URL: process.env.VITE_AUTH0_T3_KITS_CALLBACK_URL,
  VITE_AUTH0_T3_KITS_LOGOUT_URL: process.env.VITE_AUTH0_T3_KITS_LOGOUT_URL,
  // omnichannel admin
  VITE_AUTH0_OMNICHANNEL_ADMIN_DOMAIN:
    process.env.VITE_AUTH0_OMNICHANNEL_ADMIN_DOMAIN,
  VITE_AUTH0_OMNICHANNEL_ADMIN_AUDIENCE:
    process.env.VITE_AUTH0_OMNICHANNEL_ADMIN_AUDIENCE,
  VITE_AUTH0_OMNICHANNEL_ADMIN_CLIENT_ID:
    process.env.VITE_AUTH0_OMNICHANNEL_ADMIN_CLIENT_ID,
  VITE_AUTH0_OMNICHANNEL_ADMIN_CALLBACK_URL:
    process.env.VITE_AUTH0_OMNICHANNEL_ADMIN_CALLBACK_URL,
  VITE_AUTH0_OMNICHANNEL_ADMIN_LOGOUT_URL:
    process.env.VITE_AUTH0_OMNICHANNEL_ADMIN_LOGOUT_URL,
  // pointcast
  VITE_AUTH0_POINTCAST_DOMAIN: process.env.VITE_AUTH0_POINTCAST_DOMAIN,
  VITE_AUTH0_POINTCAST_AUDIENCE: process.env.VITE_AUTH0_POINTCAST_AUDIENCE,
  VITE_AUTH0_POINTCAST_CLIENT_ID: process.env.VITE_AUTH0_POINTCAST_CLIENT_ID,
  VITE_AUTH0_POINTCAST_CALLBACK_URL:
    process.env.VITE_AUTH0_POINTCAST_CALLBACK_URL,
  VITE_AUTH0_POINTCAST_LOGOUT_URL: process.env.VITE_AUTH0_POINTCAST_LOGOUT_URL,
  // shopify
  VITE_SHOPIFY_SHOP_EQUIPMENTSHARE_COM_SHOPIFY_FEATURED_PRODUCTS_PUBLIC_TOKEN:
    process.env
      .VITE_SHOPIFY_SHOP_EQUIPMENTSHARE_COM_SHOPIFY_FEATURED_PRODUCTS_PUBLIC_TOKEN,
  VITE_SHOPIFY_SHOP_EQUIPMENTSHARE_COM_SHOPIFY_FEATURED_PRODUCTS_BASE_URL:
    process.env
      .VITE_SHOPIFY_SHOP_EQUIPMENTSHARE_COM_SHOPIFY_FEATURED_PRODUCTS_BASE_URL,
};

/* manual env checking because Joi is a large dependency */
/* istanbul ignore if */
if (env("VITE_USER_NODE_ENV") !== "test") {
  Object.keys(envVars).forEach((requiredKey) => {
    const val = envVars[requiredKey];
    if (!val || typeof val !== "string") {
      throw new Error(
        `Missing env value! ${requiredKey} must be a defined string.`
      );
    }
  });
}

export const environment =
  env("VITE_USER_NODE_ENV") === "production" ? "production" : "staging";

export const isTest = Boolean(env("VITE_USER_NODE_ENV") === "test");
export const isProduction = Boolean(environment === "production");
export const isStaging = !isProduction;
export const isDevelopment = Boolean(
  isStaging && /localhost:\d+/.test(window.location.href)
);
export const isIsolated = new URLSearchParams(location.search).get("mountApp");

export const isPrerender = Boolean(
  /^Prerender:/.test(window.navigator.userAgent)
);

export const isAppletPicker = [
  "localhost",
  "omnichannel-applets.equipmentshare.com",
  "staging-omnichannel-applets.equipmentshare.com",
].includes(window.location.hostname);
