import { useEffect, useState } from "react";
import { singletonHook } from "react-singleton-hook";

import { KeywordRef } from "./types";

import { useFlag } from "~/LaunchDarkly";
import { useOmnichannelApi } from "~/OmnichannelApi";

const useKeywordsImpl = () => {
  const hasAuthorization = useFlag("applet-es-keyword-linker");
  const api = useOmnichannelApi();

  const [keywords, setKeywords] = useState<Array<KeywordRef> | undefined>();

  const [isFetchingKeywords, setIsFetchingKeywords] = useState<boolean>(false);

  useEffect(() => {
    if (hasAuthorization && !keywords?.length && !isFetchingKeywords) {
      setIsFetchingKeywords(true);
      api["static.webflow.collections.items.get"]({
        collection_slug: "keyword-references",
        path_name: "keyword-linker",
        item_slug: "_all",
        file_type: "json",
      })
        .then((res) => {
          setKeywords(res.data as Array<KeywordRef>);
        })
        .catch(console.error);
      // do not setIsFetchingKeywords(false) or it could trigger infinite loop of failing requests
    }
  }, [hasAuthorization, isFetchingKeywords]);

  return keywords;
};

export const useKeywords = singletonHook(undefined, useKeywordsImpl);
