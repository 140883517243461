import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";

import { Observer } from "./Observer";
import { makeMutationObserverCallback } from "./utils";

import { isAppletPicker, isDevelopment } from "~/env";
import { useKeywords } from "~/hooks/useKeywords";
import { useFlag } from "~/LaunchDarkly";

const Applet: React.FC = () => {
  const hasAuthorization = useFlag("applet-es-keyword-linker");

  const [shouldFetchKeywords, setShouldFetchKeywords] =
    useState<boolean>(false);
  const [observer, setObserver] = useState<MutationObserver>();

  useEffect(() => {
    if (shouldFetchKeywords) {
      observer?.disconnect();
    } else {
      // check for new mount points on the DOM with the browser native MutationObserver interface
      const mutationCallback: MutationCallback = makeMutationObserverCallback(
        () => {
          // if (isDevelopment) {
          //   console.info("keywordLinkerCallback", el);
          // }
          setShouldFetchKeywords(true);
        }
      );

      const localObserver = new MutationObserver(mutationCallback);

      localObserver.observe(document.body, {
        attributeFilter: ["keyword-linker"],
        childList: true,
        subtree: false,
      });

      setObserver(localObserver);
    }

    return () => observer?.disconnect();
  }, [shouldFetchKeywords]);

  return hasAuthorization ? <RenderObserver /> : null;
};

const RenderObserver: React.FC = () => {
  const keywords = useKeywords();

  return keywords ? <Observer keywords={keywords} /> : null;
};

export const mount = async (el: HTMLDivElement) => {
  if ((isDevelopment || isAppletPicker) && el.getAttribute("applet-picker")) {
    const elLorem = document.createElement("div");
    document.body.appendChild(elLorem);

    (await import("./Tester")).mount(elLorem);
  } else {
    const root = createRoot(el);
    root.render(<Applet />);
  }
};
