import { Alert, ConfigProvider, theme } from "antd";
import enUS from "antd/locale/en_US";
import React, { PropsWithChildren, useEffect } from "react";

import { AppletWrapperProps } from "./types";

import { APPLET_MANIFEST } from "~/AppletManifest";
import { GlobalStyles, themeConfig } from "~/Constant/Theme";
import { isDevelopment, isProduction, isTest } from "~/env";

/**
 * Applets should be wrapped in this component to automatically apply CSS
 */
export const AppletWrapper: React.FC<PropsWithChildren<AppletWrapperProps>> = ({
  children,
  appletName,
  reset,
  darkMode,
  style,
  devFixedWidth,
  isComponentWrapper,
}) => {
  const { themeName } = APPLET_MANIFEST[appletName];

  const { darkAlgorithm } = theme;

  useEffect(() => {
    window.appletDebug(`AppletWrapper > Mounting ${appletName}`);

    return () => window.appletDebug(`AppletWrapper > Unmounting ${appletName}`);
  }, []);

  return (
    <Alert.ErrorBoundary
      message={isProduction ? "Something went wrong!" : undefined}
      description={
        isProduction
          ? "Our engineers have been notified.  Please try again or contact us if you continue to experience issues."
          : undefined
      }
    >
      <ConfigProvider
        theme={
          darkMode
            ? { algorithm: darkAlgorithm }
            : themeName
            ? themeConfig[themeName]
            : undefined
        }
        locale={enUS}
      >
        <GlobalStyles
          $reset={reset}
          $themeName={themeName}
          $darkMode={darkMode}
        >
          <div
            className={isComponentWrapper ? undefined : "fadeInFast"}
            style={{
              maxWidth: !isProduction && devFixedWidth ? "1440px" : undefined,
              margin: !isProduction && devFixedWidth ? "auto" : undefined,
              ...style,
            }}
          >
            {children}
          </div>
        </GlobalStyles>
        {isDevelopment && !isTest && !isComponentWrapper && <ConsoleLogTheme />}
      </ConfigProvider>
    </Alert.ErrorBoundary>
  );
};

const ConsoleLogTheme: React.FC = () => {
  const { token } = theme.useToken();

  useEffect(() => {
    console.log("[dev] const { token } = theme.useToken()", token);
  }, [token]);

  return null;
};
