import React, { useEffect, useState } from "react";
import { Product, WithContext } from "schema-dts";

import { JSONLDComponent } from "../JSONLDComponent";

import {
  RENTAL_DAILY_OFFER,
  RENTAL_MONTHLY_OFFER,
  RENTAL_WEEKLY_OFFER,
} from "./constants";
import { EquipmentClassJson } from "./types";
import { buildStructuredDataFromEquipmentClass } from "./utils";

import { useOmnichannelApi } from "~/OmnichannelApi";

export const ProductSchema: React.FC = () => {
  const api = useOmnichannelApi();

  const [productData, setProductData] = useState<WithContext<Product>>();
  const [rawData, setRawData] = useState<EquipmentClassJson>();

  useEffect(() => {
    const matches = window.location.pathname.match(
      /^\/rent\/equipment-classes\/([^/]+)/i
    );

    if (matches) {
      const [, item_slug] = matches;

      api["static.webflow.collections.items.get"]({
        collection_slug: "equipment-classes",
        path_name: "items",
        item_slug,
        file_type: "json",
      }).then(({ data }) => {
        setRawData(data as EquipmentClassJson);
        setProductData(
          buildStructuredDataFromEquipmentClass(data as EquipmentClassJson)
        );
      });
    }
  }, []);

  if (!rawData || !productData) {
    return null;
  }

  const _daily = { ...RENTAL_DAILY_OFFER };
  const _weekly = { ...RENTAL_WEEKLY_OFFER };
  const _monthly = { ...RENTAL_MONTHLY_OFFER };

  if (rawData.price_per_day_min && rawData.price_per_day_max) {
    _daily.priceSpecification = {
      "@type": "PriceSpecification",
      price: rawData.price_per_day_min,
      minPrice: rawData.price_per_day_min,
      maxPrice: rawData.price_per_day_max,
      priceCurrency: "USD",
    };
  } else {
    _daily.price = 0;
    _daily.priceCurrency = "USD";
  }

  if (rawData.price_per_week_min && rawData.price_per_week_max) {
    _weekly.priceSpecification = {
      "@type": "PriceSpecification",
      price: rawData.price_per_week_min,
      minPrice: rawData.price_per_week_min,
      maxPrice: rawData.price_per_week_max,
      priceCurrency: "USD",
    };
  } else {
    _weekly.price = 0;
    _weekly.priceCurrency = "USD";
  }

  if (rawData.price_per_month_min && rawData.price_per_month_max) {
    _monthly.priceSpecification = {
      "@type": "PriceSpecification",
      price: rawData.price_per_month_min,
      minPrice: rawData.price_per_month_min,
      maxPrice: rawData.price_per_month_max,
      priceCurrency: "USD",
    };
  } else {
    _monthly.price = 0;
    _monthly.priceCurrency = "USD";
  }

  return (
    <React.Fragment>
      <JSONLDComponent
        structuredData={{
          ...productData,
          offers: [_daily, _weekly, _monthly],
        }}
      />
    </React.Fragment>
  );
};
