import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { WithContext, LocalBusiness } from "schema-dts";

import { JSONLDComponent } from "../JSONLDComponent";

import { LocationJson } from "./types";
import { buildStructuredData } from "./utils";

import { ES_BASE_URL } from "~/Constant";
import { useOmnichannelApi } from "~/OmnichannelApi";
import { helmetTitle } from "~/utils";

export const LocalBusinessSchema: React.FC = () => {
  const api = useOmnichannelApi();

  const [locationData, setLocationData] =
    useState<WithContext<LocalBusiness>>();
  const [data, setData] = useState<LocationJson>();

  useEffect(() => {
    const matches = window.location.pathname.match(/^\/locations\/([^/]+)/i);

    if (matches) {
      const [, item_slug] = matches;
      api["static.webflow.collections.items.get"]({
        collection_slug: "locations",
        path_name: "items",
        item_slug,
        file_type: "json",
      }).then(({ data: rawData }) => {
        const data = rawData as LocationJson;
        setData(data);
        setLocationData(buildStructuredData(data));
      });
    }
  }, []);

  if (!locationData) {
    return null;
  }

  const title = helmetTitle([
    `Equipment and Tool Rental - ${data?.name} - ${data?.city} - ${data?.region_state_abbreviation}`,
  ]);

  return (
    <React.Fragment>
      {data && (
        <Helmet>
          <title>{title}</title>
          <meta content={data.meta_description} name="description" />

          <meta content={title} name="og:title" />
          <meta content={data.meta_description} name="og:description" />
          <meta
            content={`${ES_BASE_URL}/locations/${data.slug}`}
            name="og:url"
          />
          <meta
            content="https://assets-global.website-files.com/60cb2013a506c737cfeddf74/620bbc52f1de86cc1ff1e219_Company%20Page-100.jpg"
            name="og:image"
          />
          {/*
          <meta content={title} name="twitter:title" />
          <meta content={data.meta_description} name="twitter:description" /> */}
        </Helmet>
      )}
      <JSONLDComponent structuredData={locationData} />
    </React.Fragment>
  );
};
