/* eslint-disable jest/require-hook */
import { useIdentity } from "@equipmentshare/auth0-react";
import { useEffect, useState } from "react";

import { Client } from "./openapi";
import { addInterceptors, makeOpenApiClient } from "./utils";

import { useReduxAuthToken } from "~/hooks/useReduxAuthToken";
import { useWindowFocus } from "~/hooks/useWindowFocus";

/** API definition can be updated by running `yarn openapi` in the omnichannel-api repo */
export const useOmnichannelApi = (): Client => {
  const client = makeOpenApiClient();

  addInterceptors(client);

  return client;
};

/**
 *  Returns an API client that does not have interceptors to notify of bad POST/PUT/PATCH calls.
 *  Use this client for posting analytics and other data in the background.
 */
export const useOmnichannelApiSilently = (): Client => {
  useWindowFocus();

  const client = makeOpenApiClient();

  return client;
};

export const useOmnichannelApiWithAuth = (): Client => {
  useWindowFocus();

  const identity = useIdentity();

  const client = makeOpenApiClient({
    Authorization: `Bearer ${identity.accessToken}`,
  });

  addInterceptors(client);

  return client;
};

export const useOmnichannelApiWithAuthSilently = (): Client => {
  useWindowFocus();

  const identity = useIdentity();

  const client = makeOpenApiClient({
    Authorization: `Bearer ${identity.accessToken}`,
  });

  return client;
};

export const useOmnichannelApiWithReduxAuth = (): Client | undefined => {
  useWindowFocus();

  const token = useReduxAuthToken();

  if (!token) {
    return undefined;
  }

  const client = makeOpenApiClient({ Authorization: `Bearer ${token}` });

  addInterceptors(client);

  return client;
};

/**
 * This hook should be used in dependency arrays for checking useOmnichannelApiWithReduxAuth
 */
export const useOmnichannelApiWithReduxAuthIsReady = (): boolean => {
  const token = useReduxAuthToken();

  const [isReady, setIsReady] = useState<boolean>(Boolean(token));

  useEffect(() => {
    if (token && !isReady) {
      setIsReady(true);
    } else if (isReady) {
      setIsReady(false);
    }
  }, [token]);

  return isReady;
};
